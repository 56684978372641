<template>
  <div class="articleCon">
    <div class="altsMenu">
      <div class="menuItem" :style="getListAct(itemAct === index)" v-for="(item, index) in alts" :key="index" :class="itemAct === index ? 'itemAct': ''" @click="selMenu(item,index)">{{ item.name }}</div>
    </div>
    <div class="altsList">
      <div class="listCon">
        <div class="listItem" v-for="(item, index) in actAlts" :key="index">
          <img :src="item.url" alt="" v-if="item.type === 1" @click="openImgMod(item.url)"/>
          <video controls="controls" v-if="item.type === 2">
            <source :src="item.url"/>
          </video>
        </div>
      </div>
    </div>
    <div class="imgMod" v-if="actUrl">
      <div class="imgCon">
        <Icon type="md-close-circle" @click="actUrl = ''" />
        <img :src="actUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getConfig"])
  },
  data() {
    return {
      alts: [],
      itemAct: -1,
      actAlts: {
        atlas: {}
      },
      webSite: {},
      actUrl: ""
    };
  },
  created() {
    this.webSite = this.getConfig;
    this.alts = this.$route.name === "videos" ? this.getConfig.videoType : this.getConfig.atlList;
    this.actAlts = this.$route.name === "videos" ? this.getConfig.videos : this.getConfig.album;
  },
  watch: {
    getConfig() {
      this.webSite = this.getConfig;
      this.alts = this.$route.name === "videos" ? this.getConfig.videoType : this.getConfig.atlList;
      this.actAlts = this.$route.name === "videos" ? this.getConfig.videos : this.getConfig.album;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    openImgMod(url) {
      this.actUrl = url;
    },
    getListAct(type) {
      if (type) {
        return {
          backgroundColor: this.getConfig.pageColor,
          color: "#fff"
        }
      }
    },
    linkToArticle(id) {
      this.$router.push({ name: "article", query: { id: id } });
    },
    getTime(time) {
      if (!time) {
        return "";
      }
      let d = new Date(time);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let date = d.getDate();
      // let hour = d.getHours();
      // let minute = d.getMinutes();
      // let second = d.getSeconds();
      return year + "/" + month + "/" + date;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    selMenu(item,idx) {
      this.itemAct = idx;
      this.actAlts = [];
      let arr = this.$route.name === "pageVideos" ? this.getConfig.videos : this.getConfig.album;
      for (let i of arr) {
        if (i.atlas === item.str) {
          this.actAlts.push(i)
        }
      }
    }
  }
};
</script>
